<template>
  <ProductCard
    style="width: 320px; height: 320px"
    class="block relative mb-1 z-10 shadow-sm hover:shadow-md rounded-md bg-white dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700"
    :product="product"
    :index="index"
    :listId="listId"
    :listName="listName"
  >
    <template
      v-slot="{
        product: {
          thumbnail: { src, description, bgColor },
          texts: { primaryAttribute, secondaryAttribute, remainingAttributes },
          msrp,
          discount,
          bullets,
        },
        displayLabels,
      }"
    >
      <div
        class="relative p-1 w-full flex justify-center items-center"
        style="height: 128px"
        :style="showThumbnailBgColor && !!bgColor ? `background: ${bgColor}` : ''"
      >
        <img
          :src="image(src)"
          class="object-contain"
          style="height: 120px; width: 150px"
          :alt="`Add North 3D filament ${description}`"
        />
        <div
          v-for="({ text, classes }, index) in displayLabels"
          :key="text"
          class="absolute left-0 top-0"
          :style="`margin-top: ${index * 2}rem`"
        >
          <div
            class="h-0 relative"
            :class="classes"
            :style="`width: ${
              9 - index
            }rem; border-top-width: 28px; border-right: 10px solid transparent;`"
          >
            <div
              style="top: -29px"
              class="text-sm absolute inset-0 py-1 pl-4 overflow-visible font-medium uppercase"
              v-text="text"
            />
          </div>
        </div>
      </div>
      <div class="px-4">
        <div style="height: 68px">
          <div class="w-full flex space-x-2 justify-between">
            <div class="flex-shrink mr-2 overflow-hidden">
              <h4 class="font-medium text-lg leading-tight truncate" v-html="primaryAttribute" />
              <h5 class="font-light text-base truncate" v-html="secondaryAttribute" />
              <h6
                class="font-medium text-sm truncate"
                v-if="remainingAttributes"
                v-html="remainingAttributes"
              />
            </div>
            <div class="flex-shrink-0">
              <ProductPrice
                :msrp="msrp"
                :currency="$store.state.store.currency"
                :discount="discount"
                :showWithVat="$store.state.store.showWithVat"
                :quantity="1"
              />
            </div>
          </div>
        </div>
        <div class="mb-2" style="height: 63px">
          <div
            class="flex flex-shrink items-center text-sm"
            v-for="(bullet, index) in bullets"
            :key="`${index}${bullet}`"
          >
            <svg class="w-4 h-4 fill-current" viewBox="0 0 24 24">
              <path
                d="M12,10A2,2 0 0,0 10,12C10,13.11 10.9,14 12,14C13.11,14 14,13.11 14,12A2,2 0 0,0 12,10Z"
              />
            </svg>
            <p
              class="w-full whitespace-nowrap truncate overflow-hidden"
              style="min-width: 0"
              v-html="bullet"
            />
          </div>
        </div>
        <button
          style="min-width: 100px"
          class="w-full main-button flex-shrink-0"
          v-text="$t('shop.view')"
        />
      </div>
    </template>
  </ProductCard>
</template>
<script>
import imgixClient from "../../services/imgixClient";
import ProductCard from "./ProductCard.vue";
import ProductPrice from "./ProductPrice.vue";

export default {
  name: "ProductCardDefault",
  components: {
    ProductCard,
    ProductPrice,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    showThumbnailBgColor: {
      type: Boolean,
      default: false,
    },
    listName: {
      type: String,
      required: true,
    },
    listId: {
      type: String,
      required: true,
    },
  },
  methods: {
    image(path) {
      return imgixClient.buildURL(path, {
        auto: "format,compress",
        ch: "DPR",
        q: 100,
        w: 150,
        fit: "clip",
      });
    },
  },
};
</script>
