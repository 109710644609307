var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'flex flex-row items-end justify-end space-x-1': _vm.horizontal,
  }},[(_vm.discount && _vm.discount.discountPercentage > 0 && _vm.showSales)?_c('div',{staticClass:"line-through font-medium flex-shrink-0 text-right",class:{
      'text-lg leading-5': _vm.size === 'large',
      'text-sm': _vm.size === 'small',
    }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.msrp,{ currency: _vm.currency, quantity: _vm.quantity, showWithVat: _vm.showWithVat }))+" ")]):_vm._e(),_c('div',{staticClass:"leading-none font-medium flex-shrink-0 text-right",class:{
      'text-red-400': _vm.discount && _vm.discount.discountPercentage > 0,
      'text-lg': _vm.size === 'medium',
      'text-2xl': _vm.size === 'large',
      'text-sm': _vm.size === 'small',
    }},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.msrp,{ currency: _vm.currency, discount: !!_vm.discount ? _vm.discount.discountPercentage : 0, quantity: _vm.quantity, showWithVat: _vm.showWithVat, }))+" ")]),(_vm.discount && _vm.discount.discountPercentage > 0 && _vm.showSales)?_c('div',{staticClass:"text-red-400 overflow-ellipsis overflow-hidden w-full truncate text-right",class:{
      'text-xs leading-4': _vm.size === 'large',
      'text-sm': _vm.size === 'medium',
      'text-xs': _vm.size === 'small',
    },domProps:{"textContent":_vm._s(_vm.discount.type === 'agreement' ? _vm.$t('product.agreementPrice') : _vm.discount.name)}}):_vm._e(),(_vm.showVatText)?_c('div',{staticClass:"text-right flex-shrink-0",class:{
      'text-xs leading-4': _vm.size === 'large',
      'text-sm': _vm.size === 'medium',
      'text-xs': _vm.size === 'small',
    },domProps:{"textContent":_vm._s(_vm.$t(`store.vat.${_vm.showWithVat ? 'incVat' : 'exVat'}`))}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }